
import {defineComponent, ref, reactive, onMounted, onBeforeMount, toRefs, watch} from 'vue';
import {useRoute, useRouter,onBeforeRouteUpdate} from 'vue-router';
import {useStore} from "vuex";
import navBarSlideOut from "@/components/nav/navBarSlideOut.vue";
import {createLogger} from 'vuex';
import axios from '@/api/http.js';

interface ObjValidate {
  item: string
  action_url: string
  action_target: string
  [key: string]: any
}

export default defineComponent({
  name: 'navBar',
  props: ['msg'],
  components:{
    navBarSlideOut
  },
  setup(props, context) {

    const client = localStorage.getItem("eventClient");
    onBeforeRouteUpdate((to: any) => {
      // console.log(to,"===========")
      if (to.path == '/tracks' || to.path == '/content') {
        path.value = '/allContents';
      } else {
        path.value = to.path;
      }
    });
    onBeforeMount(() =>{
      getNotificationNum();
    });
    onMounted(() =>{
      setInterval(function(){
        getNotificationNum();
      },60000)
      
    })
    const store = useStore();
    const route = useRoute(); // 第一步
    const router = useRouter(); // 第一步
    // 进入了notification页面就刷新navBar的未读数量
    watch(() => store.state.notification.notificationChange, (val, old) => {
      getNotificationNum();
    })
    // const href = window.location.href;
    // const index = href.indexOf("#");
    // const sb = href.substring(index+1,href.length);
    // console.log("sb:",sb)
    // const path = ref(sb);
    const path = ref(route.path);
    if (path.value == '/tracks' || path.value == '/content') {
      path.value = '/allContents';
    }
    // console.log('path:', path.value);
    const data = reactive({
      navItems: [
        // {name: 'Home', index: '/home'},
        // {name: 'All Content', index: '/content'},
        // {name: 'Contributors', index: '/contributors'},
        // {name: 'Presentation Builder', index: '/presentation'},
        // {name: 'Notification', index: '/notification'},
        // {name: 'My Favorites', index: '/favorites'},
      ],
      notificationNumber:0,
    });
    // const formatData = (data) => {
    //   let arr = [];
    //   data.map(item => {
    //     let obj = {
    //       name:'',
    //       index:'',
    //     };
    //     obj.name = item.item;
    //     obj.index = item.link;
    //     // obj['name'] = item.item;
    //     // obj['index'] = item.link;
    //     arr.push(obj);
    //   });
    //   console.log(arr);
    //   return arr;
    // };


    const formatData = (d:Array<ObjValidate>) => {
      let arr = [];
      d.map(item => {
        let obj = {
        };
        obj['icon'] = item.icon;
        obj['iconActive'] = item.iconActive;
        obj['name'] = item.item;
        obj['index'] = item.action_target=='inner'?item.action_url:'';
        obj['action_target'] = item.action_target;
        obj['action_url'] = item.action_url;
        arr.push(obj);
      });
      return arr;
    };
    const getData = () => {
      axios.request({
        method: 'PATCH',
        url: '/virtual/eventsNav',
        data: {
          meetingId: 'a7yyxczuty0qpjo2393gmyad',
          // meetingId: localStorage.eventsMeetingId,

        }
      }).then(res => {
        data.navItems = formatData(res.data.data[0].contents);
      });
    };
    getData();
    const getNotificationNum = () => {
      axios.request({
        method: 'PATCH',
        url: '/virtual/notificationsNum',
        data: {
          meetingId: 'mg9lr5rzd62to2hcidef11id'
          // meetingId: localStorage.eventsMeetingId,
        }
      }).then(res => {
        // console.log('2222222',res);
        data.notificationNumber = res.data.total;
        // data.notificationNumber = res.data.data.length;
      }).catch(err => {
        console.log(err);
      })
    }
    
    const clickSetting = () => {
      window['gtag']('event', 'navBarToggle', {
        event_category: 'navBar',
        event_label: 'navBarToggle',
        send_to: "UA-104780762-24",
      })
      // data.shownavBarSlideOut = !data.shownavBarSlideOut;
      let isOpen = store.state.appStore.navBarSlideOut;
      store.commit("setnavBarSlideOut", !isOpen);
    };
    const recordGa = (d)=>{
      if(d.action_target=='external'){
        window.open(d.action_url)
      }
      window['gtag']('event', 'Navigation Click', {
        event_category: 'Navigation Menu',
        event_label: d.name,
        send_to: "UA-104780762-24",
      })
    }
    return {
      ...toRefs(data),
      path,
      formatData,
      getNotificationNum,
      route,
      router,
      client,
      store,
      clickSetting,
      recordGa
    };
  },
});
