import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bfd3b9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navBarSlideOut" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "notificationNum"
}
const _hoisted_5 = { class: "bottomImg" }
const _hoisted_6 = {
  key: 0,
  src: "https://cx-app-inpixon.uc.r.appspot.com/files/event/homeLogoBig.png",
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("i", {
        class: "iconfont icon-guanbi",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeNavBarSlideOut && _ctx.closeNavBarSlideOut(...args)))
      }),
      _createVNode(_component_el_menu, {
        "default-active": _ctx.path,
        class: "el-menu-vertical-demo",
        router: true,
        "active-text-color": "#About7BE4FF"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navItems, (item, index) => {
            return (_openBlock(), _createBlock(_component_el_menu_item, {
              index: item.index,
              key: index,
              class: "leftNavContainer",
              onClick: ($event: any) => (_ctx.recordGa(item))
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("img", {
                  style: {"margin-right":"10px"},
                  src: item.iconActive,
                  alt: ""
                }, null, 8, _hoisted_2), [
                  [_vShow, item.index==_ctx.route.path]
                ]),
                _withDirectives(_createElementVNode("img", {
                  style: {"margin-right":"10px"},
                  src: item.icon,
                  alt: ""
                }, null, 8, _hoisted_3), [
                  [_vShow, item.index!=_ctx.route.path]
                ]),
                _createElementVNode("span", null, _toDisplayString(item.name), 1),
                (item.name=='Notifications' && _ctx.notificationNumber && _ctx.showNumer > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.notificationNumber), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["index", "onClick"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["default-active"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.client=='inpixon')
        ? (_openBlock(), _createElementBlock("img", _hoisted_6))
        : _createCommentVNode("", true)
    ])
  ]))
}