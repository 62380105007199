
import {defineComponent, ref, reactive, computed, watch, toRefs, onBeforeMount, onMounted} from "vue";
import topBar from "@/components/nav/topBar.vue";
import navBar from "@/components/nav/navBar.vue";
import footBar from "@/components/nav/footBar.vue";
import footerBarInpixon from '@/components/nav/inpixon/footBar.vue'
import footerBarAruba from '@/components/nav/aruba/footBar.vue'
import {useStore} from 'vuex';
import navBarSlideOut from "@/components/nav/navBarSlideOut.vue";
import axios from "@/api/http";

export default defineComponent({
  name: "index",
  components: {
    topBar,
    navBar,
    footBar,
    footerBarInpixon,
    footerBarAruba,
    navBarSlideOut
  },
  setup() {
    window.addEventListener("resize", function () {
      let width = window.innerWidth;
      if (width < 992) {
        data.showLeftBar = false;
      } else {
        data.showLeftBar = true;
      }
    })
    onBeforeMount(() => {
      getNotificationNum();
    });
    onMounted(() => {
      setInterval(function () {
        getNotificationNum();
      }, 60000)

    })
    const client = localStorage.getItem("eventClient");
    const backgroundImg = {
      'inpixon':'https://cx-app-inpixon.uc.r.appspot.com/files/event/background.png',
      'Aruba':'https://cx-app-aruba.uc.r.appspot.com/files/event/background.png',
    }
    // window.addEventListener("click",(e)=>{
    //   console.log(e.target)
    //   // store.commit("setnavBarSlideOut",false);
    // })
    const store = useStore();
    watch(() => store.state.notification.notificationChange, (val, old) => {
      getNotificationNum();
    })
    const data = reactive({
      notificationNumber: 0,
      showLeftBar: true,
      formLabelWidth: '120px',
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      dialogTitle: 'Edit profile',
    })
    const clickRight = (e) => {
      store.commit("setnavBarSlideOut", false);
      store.commit("setSearchSlideOut", false);
    }
    const hideSearchBox = (e) => {
      store.commit("setSearchSlideOut", false);
    }
    const clickSetting = () => {
      console.log("==== 3333=====",)
      let isOpen = store.state.appStore.navBarSlideOut;
      store.commit("setnavBarSlideOut", !isOpen);
    }
    const getWindowWidth = () => {
      let width = window.innerWidth;
      if (width < 992) {
        data.showLeftBar = false;
      } else {
        data.showLeftBar = true;
      }
    }
    getWindowWidth();

    const getNotificationNum = () => {
      axios.request({
        method: 'PATCH',
        url: '/virtual/notificationsNum',
        data: {
          meetingId: 'mg9lr5rzd62to2hcidef11id'
          // meetingId: localStorage.eventsMeetingId,
        }
      }).then(res => {
        // console.log('2222222',res);
        data.notificationNumber = res.data.total;
        // data.notificationNumber = res.data.data.length;
      }).catch(err => {
        console.log(err);
      })
    }
    const clickRqMeetingBtn = ()=>{
      window.open("https://connect.arubanetworks.com/request-a-meeting")
    }

    return {
      ...toRefs(data),
      store,
      hideSearchBox,
      clickRight,
      client,
      clickSetting,
      getNotificationNum,
      getWindowWidth,
      backgroundImg,
      clickRqMeetingBtn
    };
  },
});
