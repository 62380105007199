import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64f4792a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "Home"
}
const _hoisted_2 = { class: "leftRight" }
const _hoisted_3 = { class: "middle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navBar = _resolveComponent("navBar")!
  const _component_topBar = _resolveComponent("topBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footBar = _resolveComponent("footBar")!
  const _component_footerBarInpixon = _resolveComponent("footerBarInpixon")!
  const _component_navBarSlideOut = _resolveComponent("navBarSlideOut")!

  return (_ctx.client=='inpixon'||_ctx.client=='Aruba')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("i", {
            class: "settingIcon iconfont icon-shezhi",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickSetting && _ctx.clickSetting(...args)))
          }, null, 512), [
            [_vShow, !_ctx.showLeftBar]
          ]),
          _withDirectives(_createElementVNode("div", {
            class: "leftContainer",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideSearchBox && _ctx.hideSearchBox(...args)))
          }, [
            _createVNode(_component_navBar)
          ], 512), [
            [_vShow, _ctx.showLeftBar]
          ]),
          _createElementVNode("div", {
            class: "rightContainer",
            style: _normalizeStyle( {backgroundImage:'url('+_ctx.backgroundImg[_ctx.client]+')'})
          }, [
            _createVNode(_component_topBar),
            _createVNode(_component_router_view, {
              onClick: _ctx.hideSearchBox,
              class: "rightContent"
            }, null, 8, ["onClick"]),
            (_ctx.client=='vmware')
              ? (_openBlock(), _createBlock(_component_footBar, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.client=='inpixon')
              ? (_openBlock(), _createBlock(_component_footerBarInpixon, { key: 1 }))
              : _createCommentVNode("", true)
          ], 4)
        ]),
        _withDirectives(_createVNode(_component_navBarSlideOut, {
          onClick: _ctx.hideSearchBox,
          notificationCount: _ctx.notificationNumber
        }, null, 8, ["onClick", "notificationCount"]), [
          [_vShow, _ctx.store.state.appStore.navBarSlideOut]
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "Home",
        style: _normalizeStyle({background:_ctx.client=='inpixon'?'url(https://cx-app-inpixon.uc.r.appspot.com/files/event/background.png)':'unset'})
      }, [
        _createVNode(_component_topBar),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_navBar),
          _createVNode(_component_router_view, {
            style: {"flex":"1"},
            onClick: _ctx.clickRight
          }, null, 8, ["onClick"])
        ]),
        _createVNode(_component_footBar)
      ], 4))
}