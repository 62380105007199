import { App } from 'vue'

// swiper 额外组件配置
import SwiperCore, {Autoplay, Pagination, EffectCoverflow,Navigation } from 'swiper'

// swiper 单独样式 （less / scss）
// import 'swiper/swiper.scss'
// import 'swiper/components/pagination/pagination.scss'
// import "swiper/components/navigation/navigation.scss";

require('swiper/swiper.scss');
require('swiper/components/pagination/pagination.scss');
require('swiper/components/navigation/navigation.scss');

// swiper 必备组件
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Thumbs } from 'swiper';
// 使用额外组件
SwiperCore.use([Autoplay, Pagination, EffectCoverflow,Navigation,Thumbs]);

// 全局注册 swiper 必备组件
const plugins = [Swiper, SwiperSlide]

const swiper = {
    install: function (app: App<Element>) {
        plugins.forEach(item => {
            app.component(item.name, item)
        })
    }
}

export default swiper
