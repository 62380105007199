<template>
  <div class="footer">
    <el-row class="footerContainer" justify="space-between">
      <el-col :sm="{span:24,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}">
        <ul class="ulLeft footerLinkUl">
          <li class="footerLinkLi ">
        <span class="cursor:pointer" style="cursor: pointer;
    text-decoration: underline;" @click="goLink('https://usersupport.inpixon.com/hc/en-us/requests/new?ticket_form_id=360003116772&tf_360047078171=ArubaConnect')">Support</span>
          </li>
          <li class="footerLinkLi">
            <span>|</span>
          </li>
          <li class="footerLinkLi">
        <span class="textColor;cursor:pointer" style="cursor: pointer;
    text-decoration: underline;" @click="goLink('https://www.hpe.com/us/en/legal/privacy.html')">Privacy Policy</span>
          </li>
          <li class="footerLinkLi">
            <span class="">|</span>
          </li>
          <li class="footerLinkLi">
        <span class="textColor;cursor:pointer" style="cursor: pointer;
    text-decoration: underline;"
              @click="goLink('https://www.arubanetworks.com/terms-of-service')">Terms of Service</span>
          </li>
          <li class="footerLinkLi">
            <span>|</span>
          </li>
          <li class="footerLinkLi">
        <span class="textColor;cursor:pointer" style="cursor: pointer;
    text-decoration: underline;" @click="goLink('https://www.arubanetworks.com/legal')">Legal</span>
          </li>
          <li class="footerLinkLi">
            <span>|</span>
          </li>
          <li class="footerLinkLi">
        <span class="textColor;cursor:pointer" style="cursor: pointer;
    text-decoration: underline;" @click="goLink('https://www.arubanetworks.com/do-not-sell-my-personal-information')">Do Not Sell My Personal Information</span>
          </li>
        </ul>
      </el-col>
      <el-col class="copyRight" :sm="{span:24,offset:0}" :md="{span:12,push:0}" :lg="{span:12,push:0}">
        <ul class="ulRight footerLinkUl">
          <li class="footerLinkLi">
            <span>© Copyright 2022 Hewlett Packard Enterprise</span>
          </li>
        </ul>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import {reactive} from 'vue'

export default {
  name: 'footBar',
  setup() {
    const data = reactive([
      {name: '© 2022 Inpixon. All Rights Reserved', link: ""},
      {name: 'Privacy Policy', link: "https://www.hpe.com/us/en/legal/privacy.html"},
      {name: 'Do Not Sell My Personal Information', link: "https://www.inpixon.com/ccpa"},
    ])
    const goLink = (url) => {
      console.log("==== url=====", url)
      if (url) {
        window.open(url)
      }
    }
    const goPerson = () => {
      // window.open("https://www.inpixon.com/ccpa")
    }
    return {
      data,
      goLink,
      goPerson
    }
  },
}

</script>
<style scoped lang='scss'>
.footer {
  height: 65px;
  max-width: 1100px;
  width:100%;
  margin: auto;
  padding: 0;
  //width: 100%;
  //display: flex;
  //align-items: center;
  //justify-content: space-between;
  //position: absolute;
  //bottom: 0;
  border-top: 1px solid #fff;
  //left: 50%;
  //transform: translateX(-50%);
  .footerContainer {
    width: 100%;
    //display: flex;
    //justify-content: space-between;
    .copyRight {
      display: flex;
      justify-content: flex-end;
    }

    .ulLeft {
      //margin-left: 40px;
    }

    .ulRight {
      //margin-right: 35px;
    }

    .footerLinkUl {
      display: flex;
      flex-wrap: wrap;

      .footerLinkLi {
        margin-right: 5px;
      }
    }
  }

}
</style>
<style lang="scss" scope>
@media screen and (min-width: 320px) and (max-width: 992px) {
  .footer {
    padding: 0 10px!important;
    box-sizing: border-box;
    .copyRight {
      display: flex;
      justify-content: flex-start!important;
    }
  }
}
</style>
