
import {defineComponent, ref, reactive, onMounted, toRefs, watch,nextTick} from 'vue';
import {onBeforeRouteUpdate, useRoute} from 'vue-router';
import {useStore} from "vuex";
import axios from '@/api/http.js';


export default defineComponent({
  name: 'navBar',
  props: ['msg','notificationCount'],
  setup(props, context) {
    onBeforeRouteUpdate((to: any) => {
      // console.log(to,"===========")
      if (to.path == '/tracks' || to.path == '/content') {
        path.value = '/allContents';
      } else {
        path.value = to.path;
      }
    });
    // const notificationCount = props.notificationCount;
    const store = useStore();
    const route = useRoute(); // 第一步
    const client = localStorage.getItem("eventClient");
    watch(() => props.notificationCount, (val, old) => {
      data.showNumer = false;
      data.notificationNumber = val;
      nextTick(()=>{
        data.showNumer = true;
      })
    })
    // const href = window.location.href;
    // const index = href.indexOf("#");
    // const sb = href.substring(index+1,href.length);
    // console.log("sb:",sb)
    // const path = ref(sb);
    const path = ref(route.path);
    if (path.value == '/tracks' || path.value == '/content') {
      path.value = '/allContents';
    }
    // console.log('path:', path.value);
    const data = reactive({
      showNumer:true,
      navItems: [
        // {name: 'Home', index: '/home'},
        // {name: 'All Content', index: '/content'},
        // {name: 'Contributors', index: '/contributors'},
        // {name: 'Presentation Builder', index: '/presentation'},
        // {name: 'Notification', index: '/notification'},
        // {name: 'My Favorites', index: '/favorites'},
        // {name: 'WebView', index: "/webview?type=&url=https://merchandise.hpe.com/shop.axd/WorldMap"},
        // {name: 'External',action_target:'external',action_url:'https://thecxapp.com',index:''},
      ],
      notificationNumber: props.notificationCount,
    });
    const formatData = (data) => {
      let arr = [];
      data.map(item => {
        let obj = {};
        obj['icon'] = item.icon;
        obj['iconActive'] = item.iconActive;
        obj['name'] = item.item;
        obj['index'] = item.action_target=='inner'?item.action_url:'';
        obj['action_target'] = item.action_target;
        obj['action_url'] = item.action_url;
        arr.push(obj);
      });
      // console.log(arr);
      return arr;
    };
    const getData = () => {
      axios.request({
        method: 'PATCH',
        url: '/virtual/navigationSlide',
        data: {
          meetingId: 'a7yyxczuty0qpjo2393gmyad',
          // meetingId: localStorage.eventsMeetingId,
        }
      }).then(res => {
        // console.log('routes:', res.data.data);
        data.navItems = formatData(res.data.data[0].contents);
      });
    };
    getData();

    // 关闭弹出的左侧导航栏
    const closeNavBarSlideOut = () => {
      let isOpen = store.state.appStore.navBarSlideOut;
      store.commit("setnavBarSlideOut", !isOpen);
    }
    const recordGa = (d)=>{
      // console.log("==== d=====",d)
      if(d.action_target=='external'){
        window.open(d.action_url)
      }
      store.commit("setnavBarSlideOut", false);
      window['gtag']('event', 'Navigation Click', {
        event_category: 'Navigation Menu',
        event_label: d.name,
        send_to: "UA-104780762-24",
      })
    }
    return {
      ...toRefs(data),
      path,
      getData,
      closeNavBarSlideOut,
      route,
      store,
      client,
      recordGa
    };
  },
  beforeRouteUpdate(to, from, next) {
    // console.log('to:', to);
    // console.log('from:', from);
  }
});
