var _elqQ = _elqQ || [];
_elqQ.push(['elqSetSiteId', '704917861']);
_elqQ.push(['elqTrackPageView']);

(function () {
    function async_load() {
        var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true;
        s.src = '//img04.en25.com/i/elqCfg.min.js';
        var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x);
    }
    if (window.addEventListener) window.addEventListener('DOMContentLoaded', async_load, false);
    else if (window.attachEvent) window.attachEvent('onload', async_load); 
})();

console.log('sun test');

function elqGetCookie(name) {

    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {

        var position = cookies[i].indexOf('=');

        if (position > 0 && position < cookies[i].length - 1) {

            var x = cookies[i].substr(0, position);

            var y = cookies[i].substr(position + 1);

            x = x.replace(/^\s+|\s+$/g, '');

            if (x == name) {

                return unescape(y);

            }

        }

    }

    return '';

}

function elqGetCookieSubValue(name, subKey) {

    var cookieValue = elqGetCookie(name);

    if (cookieValue == null || cookieValue == '')

        return '';

    var cookieSubValue = cookieValue.split('&');

    for (var i = 0; i < cookieSubValue.length; i++) {

        var pair = cookieSubValue[i].split('=');

        if (pair.length > 1) {

            if (pair[0] == subKey) {

                return pair[1];

            }

        }

    }

    return '';

}

function elqSetCustomerGUID() {

    var elqCustomerGuid = elqGetCookieSubValue('ELOQUA', 'GUID');

    if (elqCustomerGuid != null && elqCustomerGuid != '')

        document.forms['ArubaATMDigital'].elements['elqCustomerGUID'].value = elqCustomerGuid;

    return;

}

var timerId = null, timeout = 5;

function WaitUntilCustomerGUIDIsRetrieved() {
    if (!!(timerId)) {
        if (timeout == 0) { 
            return;

        }
        if (typeof this.GetElqCustomerGUID === 'function') {
            document.forms["ArubaATMDigital"].elements["elqCustomerGUID"].value = GetElqCustomerGUID();
            return;
        }
        timeout -= 1;
    }
    timerId = setTimeout("WaitUntilCustomerGUIDIsRetrieved()", 500);
    return;
}


window.onload = elqSetCustomerGUID;
window.onload = WaitUntilCustomerGUIDIsRetrieved;
_elqQ.push(['elqGetCustomerGUID']);


function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}


function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}



function getUTMValues(url) {
    console.log('is coming elq')
    var cur_url = new URL(window.location.href);
    var utm_campaign_cookie = getCookie("utm_campaign");
    var utm_source_cookie = getCookie("utm_source");
    var utm_content_cookie = getCookie("utm_content");
    var utm_medium_cookie = getCookie("utm_medium");
    var utm_geo_cookie = getCookie("utm_geo");
    var gclid_cookie = getCookie("gclid");

    var utm_campaign = cur_url.searchParams.get("utm_campaign");
    var utm_source = cur_url.searchParams.get("utm_source");
    var utm_content = cur_url.searchParams.get("utm_content");
    var utm_medium = cur_url.searchParams.get("utm_medium");
    var utm_geo = cur_url.searchParams.get("utm_geo");
    var gclid = cur_url.searchParams.get("gclid");

    utm_campaign = (utm_campaign == null) ? utm_campaign_cookie : utm_campaign;
    utm_source = (utm_source == null) ? utm_source_cookie : utm_source;
    utm_content = (utm_content == null) ? utm_content_cookie : utm_content;
    utm_medium = (utm_medium == null) ? utm_medium_cookie : utm_medium;
    utm_geo = (utm_geo == null) ? utm_geo_cookie : utm_geo;
    gclid = (gclid == null) ? gclid_cookie : gclid;


    if(utm_campaign != null && utm_campaign != ""){
        url = url + "&utm_campaign=" + utm_campaign;
    }
    if(utm_source != null && utm_source != ""){
        url = url + "&utm_source=" + utm_source;
    }
    if(utm_content != null && utm_content != ""){
        url = url + "&utm_content=" + utm_content;
    }
    if(utm_medium != null && utm_medium != ""){
        url = url + "&utm_medium=" + utm_medium;
    }
    if(utm_geo != null && utm_geo != ""){
        url = url + "&utm_geo=" + utm_geo;
    }
    if(gclid != null && gclid != ""){
        url = url + "&gclid=" + gclid;
    }

    return url;
}

function getUTMValuesForm(form) {
    var cur_url = new URL(window.location.href);
    var utm_campaign_cookie = getCookie("utm_campaign");
    var utm_source_cookie = getCookie("utm_source");
    var utm_content_cookie = getCookie("utm_content");
    var utm_medium_cookie = getCookie("utm_medium");
    var utm_geo_cookie = getCookie("utm_geo");
    var gclid_cookie = getCookie("gclid");

    var utm_campaign = cur_url.searchParams.get("utm_campaign");
    var utm_source = cur_url.searchParams.get("utm_source");
    var utm_content = cur_url.searchParams.get("utm_content");
    var utm_medium = cur_url.searchParams.get("utm_medium");
    var utm_geo = cur_url.searchParams.get("utm_geo");
    var gclid = cur_url.searchParams.get("gclid");

    utm_campaign = (utm_campaign == null) ? utm_campaign_cookie : utm_campaign;
    utm_source = (utm_source == null) ? utm_source_cookie : utm_source;
    utm_content = (utm_content == null) ? utm_content_cookie : utm_content;
    utm_medium = (utm_medium == null) ? utm_medium_cookie : utm_medium;
    utm_geo = (utm_geo == null) ? utm_geo_cookie : utm_geo;
    gclid = (gclid == null) ? gclid_cookie : gclid;


    if(utm_campaign != null && utm_campaign != ""){
        form["utm_campaign"] = utm_campaign;
    }
    if(utm_source != null && utm_source != ""){
        form["utm_source"] = utm_source;
    }
    if(utm_content != null && utm_content != ""){
        form["utm_content"] = utm_content;
    }
    if(utm_medium != null && utm_medium != ""){
        form["utm_medium"] = utm_medium;
    }
    if(utm_geo != null && utm_geo != ""){
        form["utm_geo"] = utm_geo;
    }
    if(gclid != null && gclid != ""){
        form["gclid"] = gclid
    }

    return form;
}



function setUTMCookie(){
    var cur_url = new URL(window.location.href);
    var utm_campaign = cur_url.searchParams.get("utm_campaign");
    var utm_source = cur_url.searchParams.get("utm_source");
    var utm_content = cur_url.searchParams.get("utm_content");
    var utm_medium = cur_url.searchParams.get("utm_medium");
    var utm_geo = cur_url.searchParams.get("utm_geo");
    var gclid = cur_url.searchParams.get("gclid");

    if(utm_campaign != null){
        setCookie('utm_campaign', utm_campaign, 7);
    }
    if(utm_source != null){
        setCookie('utm_source', utm_source, 7);
    }
    if(utm_content != null){
        setCookie('utm_content', utm_content, 7);
    }
    if(utm_medium != null){
        setCookie('utm_medium', utm_medium, 7);
    }
    if(utm_geo != null){
        setCookie('utm_geo', utm_geo, 7);
    }
    if(gclid != null){
        setCookie('gclid', gclid, 7);
    }
}




function elqActivityForm(id, actionType, contentId) {
    console.log('coming nice')
    var email = localStorage.elqEmail;
    var meetingId = '7pvonav7ez8g6u9ve7isxtcb';
    console.log(email)
     // var url = "https://s704917861.t.eloqua.com/e/f2.aspx?elqFormName=ArubaATMDigitalActivity&elqSiteID=704917861";
    var url = "/digital/eloqua?action=click";
     // url = url + "&emailAddress=" + $(".user-email").html();
    //  url = url + "&email=" + $(".user-email").html();
    url = url + "&email=" + email;
    url = url + "&id=" + id;
    url = url + "&actionType=" + actionType;
    url = url + "&contentId=" + contentId;
    url = url + "&meetingId=" + meetingId;

    url = getUTMValues(url);
    //步骤一:创建异步对象
    var ajax = new XMLHttpRequest();
    //步骤二:设置请求的url参数,参数一是请求的类型,参数二是请求的url,可以带参数,动态的传递参数starName到服务端
    ajax.open('get', url);
    //步骤三:发送请求
    ajax.send();
    //步骤四:注册事件 onreadystatechange 状态改变就会调用
    ajax.onreadystatechange = function () {   
        // console.log(ajax)
        if (ajax.readyState==4 &&ajax.status==200) {
        //步骤五 如果能够进到这个判断 说明 数据 完美的回来了,并且请求的页面是存在的　　　
        　 console.log("submit to eloqua!");
    　　 }else{
           console.log(onerror);
        }
    }
   

    // $.ajax({
    //     type: "get",
    //     url: url,
    //     contentType: "text/plain;charset=utf-8",
    //     success: function (result) {
    //         console.log("submit to eloqua!");
    //     },
    //     error: function (XMLHttpRequest, textStatus, errorThrown) {
    //         console.log(errorThrown);
    //     }
    // });

    
    
}


function nextBeen(agendaId, actionType, contentId) {
    console.log("next been")
    //action is "Watch Now" or "Click on Sponsor"
    var meetingId = '7pvonav7ez8g6u9ve7isxtcb';
    var url = "/digital/apinextbee?type=virtual";
    if(agendaId != ''){
        url = url + "&sessionId=" + agendaId;
    }else{
        url = url + "&sessionId=";
    }
    if(contentId != ''){
        url = url + "&externalId=" + contentId;
    }else{
        url = url + "&externalId=";
    }
    if(actionType != ''){
        url = url + "&action=" + actionType;
    }else{
        url = url + "&action=";
    }
    url = url + "&meetingId=" + meetingId;

    //步骤一:创建异步对象
    var ajax = new XMLHttpRequest();
    //步骤二:设置请求的url参数,参数一是请求的类型,参数二是请求的url,可以带参数,动态的传递参数starName到服务端
    ajax.open('get', url);
    //步骤三:发送请求
    ajax.send();
    //步骤四:注册事件 onreadystatechange 状态改变就会调用
    ajax.onreadystatechange = function () {   
        // console.log(ajax)
        if (ajax.readyState==4 &&ajax.status==200) {
        //步骤五 如果能够进到这个判断 说明 数据 完美的回来了,并且请求的页面是存在的　　　
        　 console.log("submit to eloqua!");
    　　 }else{
           console.log(onerror);
        }
    }

}


function elqActivityLogin() {
    console.log('elqActivityLogin');
    var meetingId = '7pvonav7ez8g6u9ve7isxtcb';
    var email = localStorage.elqEmail;
    var url = "/digital/eloqua?action=login&email=" + email;
    url = url + "&meetingId=" + meetingId;
    url = getUTMValues(url);
    // $.ajax({
    //     type: "get",
    //     url: url,
    //     contentType: "text/plain;charset=utf-8",
    //     success: function (result) {
    //         console.log("submit to eloqua!");
    //     },
    //     error: function (XMLHttpRequest, textStatus, errorThrown) {
    //         console.log(errorThrown);
    //     }
    // });

    //步骤一:创建异步对象
    var ajax = new XMLHttpRequest();
    //步骤二:设置请求的url参数,参数一是请求的类型,参数二是请求的url,可以带参数,动态的传递参数starName到服务端
    ajax.open('get', url);
    //步骤三:发送请求
    ajax.send();
    //步骤四:注册事件 onreadystatechange 状态改变就会调用
    ajax.onreadystatechange = function () {   
        // console.log(ajax)
        if (ajax.readyState==4 &&ajax.status==200) {
        //步骤五 如果能够进到这个判断 说明 数据 完美的回来了,并且请求的页面是存在的　　　
        　 console.log("submit to eloqua login!");
    　　 }else{
           console.log(onerror);
        }
    }

    
    
}




function nextBeeForm(id, actionType, sessionId, email){
    var meetingId = $("#meeting_id").html();
    if(meetingId == "8fyb4lb41660jb8gtj5mw8tv" || meetingId == "amiv066ywl0u1f1ezbti15dy" || meetingId == "ubslqnze461cppiuqnx3k73d" || meetingId == "fo5v3u7tvb6t6b82mmvygk5f"){
        email_en = btoa(unescape(encodeURIComponent(email)));
        if(actionType == "Sessions"){
            transactionId =  sessionId + "_" + email_en;
        }else{
            transactionId =  actionType + "_" + email_en;
        }
        NextbeePixel.transactionId = transactionId;
        NextbeePixel.email = email_en; //base64 encode of test@mail.com; 
        NextbeePixel.activityType = actionType;
        NextbeePixel.activityName = sessionId;
        groupNames = $(".user-groupNames").html();
        if(groupNames == undefined){
            groupNames = "";
        }
        NextbeePixel.groupName = groupNames;

        try {
            logNextbeePixelActivity();
            console.log("push to next bee!");
            console.log(NextbeePixel);
        } catch (error) {
            console.error(error);
        }
    }
}


function updateNextBeePoints(){
    var meetingId = $("#meeting_id").html();
    var email =  $(".user-email").html();
    if(meetingId == "amiv066ywl0u1f1ezbti15dy" || meetingId == "fo5v3u7tvb6t6b82mmvygk5f"){
        try {
            var nextBeeInfo = $(".user-nextbee").html();
            var signature = nextBeeInfo.split(":")[0];
            var timestamp = nextBeeInfo.split(":")[1];

            $.ajax({
                url: 'https://app3.nextbee.com/nextbeeapis/20205/20191/getMemberRankByEmail',
                data: "email=" + email,
                processData: false,
                type: 'POST',
                headers: { 'api-call-signature': signature, 'ts-key': timestamp},
                success: function ( data ) {
                    console.log( data );
                    data = data.data;
                    if(data.rank != "false"){
                        $(".rank .pointsNum").html(data.region_rank);
                    }
                    if(data.sub_region_rank != "false"){
                        $(".rank .rankNum").html(data.sub_region_rank);
                    }
                    if(data.member_points.total != "false"){
                        $(".points .pointsNum").html(data.member_points.total);
                    }
                }
            });
        } catch (error) {
            console.error(error);
        }

    }
}

export default{
    getUTMValues,
    elqActivityLogin,
    elqActivityForm,
    setCookie,
    nextBeen
}



