
import {defineComponent, ref, reactive, toRefs, watch, nextTick,onMounted} from "vue";
import searchBox from "@/components/tools/search.vue";
import avator from "@/components/tools/avator.vue";
import navBarSlideOut from "@/components/nav/navBarSlideOut.vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import axios from '@/api/http.js';


export default defineComponent({
  name: "topBar",
  props: ["msg"],
  components: {
    searchBox,
    avator,
    navBarSlideOut,
  },
  setup(props, context) {
    onMounted(() =>{
      setInterval(function(){
        getGamePoints();
      },60000)
      
    })
    const router = useRouter();
    const store = useStore();
    const client = localStorage.getItem("eventClient");
    watch(() => store.state.searchAgenda.keyword, (val, old) => {
      console.log(val, old)
    })
    // 监听到用户信息发生改变就重新获取一下campusInfo的数据
    watch(() => store.state.avator.avatorChange, (val, old) => {
      data.showAvator = false;
      data.user = {};
      data.user = JSON.parse(localStorage.getItem('campusInfo'));
      nextTick(() => {
        data.showAvator = true;
      })
    })
    const data = reactive({
      pointsNum: 0,
      rankNum: 0,
      showAvator: true,
      formLabelWidth: '120px',
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      dialogFormVisible: false,
      dialogTitle: 'Edit profile',
      personDetail: {},
      shownavBarSlideOut: false,
      select: "",
      options: [
        {label: "Edit My Profile", value: "1"},
        // {label: "My Events", value: "2"},
        {label: "Log Out", value: "3"},
      ],
      user: JSON.parse(localStorage.getItem('campusInfo')),//这里campusInfo的信息可以是login调用的/m/authenticate/获取的，也可以是url直接跳转/m/profile/获取的
      timeout: null,
      cancelRequest: null,
    });
    const clickSetting = () => {
      // data.shownavBarSlideOut = !data.shownavBarSlideOut;
      let isOpen = store.state.appStore.navBarSlideOut;
      store.commit("setnavBarSlideOut", !isOpen);
    };
    const clickOption = (data) => {
      if (data.value == "1") {
        router.push({name: "Profile"});
      } else if (data.value == "2") {
        router.push({name: "Mec"});
      } else if (data.value == "3") {
        router.push({name: "Login"});
        localStorage.eventsMeetingId = "";
      }
    };
    // 带有下拉列表的go，后面再完善这个功能，别删！！！！！！！！！
    // const go = (slideData, listData) => {
    //   console.log("====slideData =====",slideData)
    //   let length = slideData.length;
    //   let data1 = "";
    //   let data2 = [];
    //   for (let i = 0; i < length; i++) {
    //     let item = slideData[i];
    //     if (item.checked) {
    //       data1 = item.label;
    //       break
    //     }
    //   }
    //   let lengthList = listData.length;
    //   for (let i = 0; i < lengthList; i++) {
    //     let item = listData[i];
    //     if(item.checked){
    //       data2.push(item);
    //     }
    //   }
    //   router.push({name: "searchAgenda"});
    //   console.log("==== data1=====",data1)
    //   console.log("==== data2=====",data2)
    // }

    const goPage = (page) => {
      console.log("====page =====", page)
      router.push(page)
    }
    const go = (data, journey, track, areasOfInterest) => {
      console.log("====dataGo =====", data, journey, track, areasOfInterest)
      // context.emit('editProfile', true );
      router.push({
        name: "searchAgenda", query: {keyword: data, journey: journey, track: track, areasOfInterest: areasOfInterest},
      });


    }
    // const search = (keyword) => {
    //   store.commit("setKeyword", keyword);
    // }

    // const search = (keyword) => {
    //   if(data.cancelRequest){
    //     data.cancelRequest();
    //   }
    //   let CancelToken = axios.CancelToken;
    //   if(data.timeout){
    //     clearTimeout(data.timeout);
    //     data.timeout = null;
    //   }
    //   data.timeout = setTimeout(()=>{
    //     axios.request({
    //       method: 'PATCH',
    //       url: '/virtual/agendas',
    //       data: {
    //         meetingId: "a7yyxczuty0qpjo2393gmyad",
    //         keyword:keyword
    //       },
    //       cancelToken: new CancelToken((c) => {
    //         data.cancelRequest = c;
    //       })
    //     }).then(res => {
    //       console.log('res:', res);
    //       router.push({name: "Tracks"});
    //     }).catch(err => {
    //       console.log(err);
    //     })
    //   },300)
    // }
    const clickTopBar = (e) => {
      // console.log("==== =====",e.target.className)
      let res = e.target.className == 'topBar' || e.target.className == 'topBarLeft' || e.target.className == 'topBarRight';
      if (res) {
        store.commit("setnavBarSlideOut", false);
        store.commit("setSearchSlideOut", false);
      }
    }
    const getGamePoints = () => {
      if (client == 'Aruba') {
        axios.request({
          method: 'PATCH',
          url: '/virtual/getGamePoints',
          data: {}
        }).then(res => {
          console.log('getGamePoints:', res);
          data.pointsNum = res.data.points;
          data.rankNum = res.data.rank;
        }).catch(err => {
          console.log(err);
        })
      }
    }
    // if (client == 'Aruba') {
    //   getGamePoints();
    // }

    return {
      ...toRefs(data),
      clickSetting,
      clickOption,
      store,
      // search,
      clickTopBar,
      go,
      client,
      goPage
    };
  },
});
