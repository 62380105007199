<template>
  <div class="footer">
     <ul class="footerLinkUl">
       <li v-for="item in data" :key="item" class="footerLinkLi textColor">{{item.name}}</li>
     </ul>
     <span class="textColor">@ 2021 Lorem ipsum dolor</span>
  </div>
</template>

<script>
import { reactive } from 'vue'
export default {
  name: 'footBar',
  setup() {
    const data = reactive([
      {name:'Support'},
      {name:'Privacy Policy'},
      {name:'Terms of Service'},
      {name:'Legal'},
    ])
 
    return {
      data
    }
  },
}

</script>
<style scoped lang='scss'>
.footer{
  height:50px;
  display: flex;
  align-items: center;
  padding-left: 200px;
  padding-right: 25px;
  justify-content: space-between;
  .footerLinkUl{
    display: flex;
    .footerLinkLi{
      margin-right: 20px;
    }
  }
}
</style>
