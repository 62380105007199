const appStore = {
  state: {
    searchSlideOut: false,
  },
  getters: {
    getSearchSlideOut: state => state.searchSlideOut
  },
  mutations: {
    setSearchSlideOut: (state, data) => state.searchSlideOut = data,
  },
}

export default appStore;
