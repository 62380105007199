const appStore = {
  state: {
    navBarSlideOut: false,
  },
  getters: {
    getnavBarSlideOut: state => state.navBarSlideOut,
  },
  mutations: {
    setnavBarSlideOut: (state, data) => state.navBarSlideOut = data,
  },
}

export default appStore;
