<template>
  <div class="footer">
    <div class="footerContainer">
      <ul class="ulLeft footerLinkUl">
        <li class="footerLinkLi textColor">
          © 2022 Inpixon. All Rights Reserved
        </li>
      </ul>
      <ul class="ulRight footerLinkUl">
        <li class="footerLinkLi textColor">
        <span class="textColor;cursor:pointer" style="cursor: pointer;
    text-decoration: underline;" @click="goLink">Privacy Policy</span>
        </li>
        <li class="footerLinkLi textColor">
          <span class="textColor">|</span>
        </li>
        <li class="footerLinkLi textColor">
          <span style="cursor: pointer;
    text-decoration: underline;" @click="goPerson">
          Do Not Sell My Personal Information
          </span>
        </li>
        <!--<li v-for="item in data" :key="item" class="footerLinkLi textColor" @click="goLink">{{ item.name }}</li>-->
      </ul>
    </div>

    <!--<span class="textColor">@ 2021 Lorem ipsum dolor</span>-->
  </div>
</template>

<script>
import {reactive} from 'vue'

export default {
  name: 'footBar',
  setup() {
    const data = reactive([
      {name: '© 2022 Inpixon. All Rights Reserved', link: ""},
      {name: 'Privacy Policy', link: "https://www.inpixon.com/privacy-statement"},
      {name: 'Do Not Sell My Personal Information', link: "https://www.inpixon.com/ccpa"},
    ])
    const goLink = () => {
      window.open("https://www.inpixon.com/privacy-statement")
    }
    const goPerson = () => {
      window.open("https://www.inpixon.com/ccpa")
    }
    return {
      data,
      goLink,
      goPerson
    }
  },
}

</script>
<style scoped lang='scss'>
.footer {
  height: 65px;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //position: absolute;
  //bottom: 0;
  border-top:1px solid #fff;
  //left: 50%;
  //transform: translateX(-50%);
  .footerContainer{
    width:100%;
    display: flex;
    justify-content: space-between;
    height:65px;
    line-height: 65px;
    .ulLeft{
      margin-left: 40px;
    }
    .ulRight{
      margin-right: 35px;
    }
    .footerLinkUl {
      display: flex;
      .footerLinkLi {
        margin-right: 5px;
      }
    }
  }

}
</style>
