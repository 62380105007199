const appStore = {
  state: {
    keyword: ""
  },
  getters: {
    getKeyword: state => state.keyword
  },
  mutations: {
    setKeyword: (state, data) => state.keyword = data
  },
}

export default appStore;
