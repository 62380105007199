<template>
  <!--<router-view v-slot="{ Component }">-->
  <!--  &lt;!&ndash; 缓存页面 &ndash;&gt;-->
  <!--  <keep-alive include="Home,Contributors">-->
  <!--    <component :is="Component" />-->
  <!--  </keep-alive>-->
  <!--</router-view>-->

  <router-view/>


  <!--<div id="app">-->
  <!--  <keep-alive>-->
  <!--    <router-view/>-->
  <!--  </keep-alive>-->
  <!--</div>-->

</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: #2c3e50;
  color: #000;
  height:100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
