const appStore = {
  state: {
    avatorChange:false
  },
  getters: {
    getAvatorChange: state => state.avatorChange,
  },
  mutations: {
    setAvatorChange: (state, data) => state.avatorChange = data,
  },
}

export default appStore;
