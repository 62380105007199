import { createStore } from 'vuex'
import appStore from './navigation/index'
import searchAgenda from './searchAgenda/index'
import searchBox from './searchBox/index'
import avator from './avator/index'
import notification from './notification/index'

export default createStore({
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
    appStore,
    searchAgenda,
    searchBox,
    avator,
    notification
  }
})
