<template>
  <div class="search">
    <el-input
        placeholder="Search..."
        class="searchBoxInput"
        custom-class="searchBoxInput"
        suffix-icon="el-icon-search"
        :style="{ width: width + 'px' }"
        v-model="input1"
        @focus="searchFocus"
        @input="inputChange"
        @keyup.enter="goSearch"
        ref="inputRef"
    >
    </el-input>
    <el-button class="x-btn goBtn" style="margin-left: 10px;min-height:auto;"
               v-show="showSearchBox&&store.state.searchBox.searchSlideOut" @click="go">Go
    </el-button>
    <!--<el-button class="x-btn goBtn" style="margin-left: 10px;min-height:auto;" v-show="showGo" @click="go">Go</el-button>-->
    <div class="searchBottomBox" v-show="showSearchBox&&store.state.searchBox.searchSlideOut">
      <div class="slide" style="margin: 10px 0;">
        <p class="closeContainer">
          <i class="iconfont icon icon-guanbi" @click="clickSearchBox"></i>
        </p>
        <swiper
            slides-per-view="auto"
            :observer="swiper_journey.observer"
            :observeParents="swiper_journey.observeParents"
            :navigation="swiper_journey.navigation"
        >
          <swiper-slide class="swiperTitle">
            Journeys:
          </swiper-slide>
          <swiper-slide v-for="item in journeysData" :class="{'x-btn2':item.isCheck}"
                        @click="clickSlideItem('journey',item)">
            {{ item.name }}
          </swiper-slide>
        </swiper>
      </div>
      <div class="slide" style="margin: 10px 0;">
        <swiper
            slides-per-view="auto"
            :observer="swiper_track.observer"
            :observeParents="swiper_track.observeParents"
            :navigation="swiper_track.navigation"
        >
          <swiper-slide class="swiperTitle">
            Tracks:
          </swiper-slide>
          <swiper-slide v-for="item in tracksData" :class="{'x-btn2':item.isCheck}"
                        @click="clickSlideItem('track',item)">
            {{ item.name }}
          </swiper-slide>
        </swiper>

      </div>

      <div class="table">
        <p v-for="item in areasOfInterestData" @click="item.isCheck = !item.isCheck">
          <el-checkbox class="checkbox" v-model="item.isCheck" @click="clickCheckbox($event)"></el-checkbox>
          <span class="text">{{ item.name }}</span>
        </p>
      </div>
    </div>
  </div>
</template>


<script>
import {ref, props, reactive, toRefs, onMounted, watch} from "vue";
import {useStore} from "vuex";
import axios from '@/api/http.js';


export default {
  name: "Search",
  props: {
    width: {
      type: String,
      default: "200",
    },
    showSearchBox: {//点击弹窗下方搜索框
      type: Boolean,
      default: false
    },
    showGo: {//显示GO按钮
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const inputRef = ref(null);
    const store = useStore();
    watch(() => store.state.searchBox.searchSlideOut, (val, old) => {
      if (!val) {
        data.areasOfInterestData.map((i) => {
          i.isCheck = false;
        })
        data.tracksData.map((i) => {
          i.isCheck = false;
        })
        data.journeysData.map((i) => {
          i.isCheck = false;
        })
      }
    })
    onMounted(() => {
      getFilterData();
    });
    let swiper_track = reactive({
      slidesPerView: 'auto',
      spaceBetween: 15,
      slideToClickedSlide: true,
      centeredSlides: false,
      initialSlide: 0,
      speed: 500,
      pagination: {
        clickable: true
      },
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
      observer: true,//observer=true//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//observeParents=true//修改swiper的父元素时，自动初始化swiper
    })
    let swiper_journey = reactive({
      slidesPerView: 'auto',
      spaceBetween: 15,
      slideToClickedSlide: true,
      centeredSlides: false,
      initialSlide: 0,
      speed: 500,
      pagination: {
        clickable: true
      },
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
      observer: true,//observer=true//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//observeParents=true//修改swiper的父元素时，自动初始化swiper
    })
    const input1 = ref("");
    // const keyup = () => {
    //   context.emit('search', input1.value);
    // };
    const data = reactive({
      showBottomBox: false,
      areasOfInterestData: [],
      tracksData: [],
      journeysData: [],
      searchList: [
        {label: "Biological Satfty Cabinets & Clean", checked: false},
        {label: "Centrifuges", checked: false},
        {label: "Lorem", checked: false},
        {
          label: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium, alias animi facere fugit hic itaque minima",
          checked: false
        },
        {label: "Biological Satfty Cabinets & Clean", checked: false},
        {label: "Biological Satfty Cabinets & Clean", checked: false},
        {label: "Biological Satfty Cabinets & Clean", checked: false},
        {label: "Biological Satfty Cabinets & Clean", checked: false},
        {label: "Biological Satfty Cabinets & Clean", checked: false},
        {label: "Biological Satfty Cabinets & Clean", checked: false},
        {label: "Biological Satfty Cabinets & Clean", checked: false},
        {label: "Biological Satfty Cabinets & Clean", checked: false},
        {label: "Biological Satfty Cabinets & Clean", checked: false},
        {label: "Biological Satfty Cabinets & Clean", checked: false},
        {label: "Biological Satfty Cabinets & Clean", checked: false},
      ],
      searchListSlide: [
        {label: 'All', checked: false},
        {label: 'Biobanking', checked: false},
        {label: 'Cell Culturl & Cell Therapy', checked: false},
        {label: 'Clinical', checked: false},
        {label: 'Food & Beverage', checked: false},
        {label: 'Biobanking', checked: false},
        {label: 'Product Support', checked: false},
        {label: 'Product Activity', checked: false},
      ],
    })

    const getFilterData = () => {
      //  const loading = ElLoading.service({
      //     lock: true,
      //   })
      axios.request({
        method: 'PATCH',
        url: '/virtual/getBoxs',
        data: {
          meetingId: 'a7yyxczuty0qpjo2393gmyad'
          // meetingId: localStorage.eventsMeetingId,
        }
      }).then(res => {
        console.log('getBoxs:', res.data)
        data.areasOfInterestData = res.data.agendaAreaLists;
        data.areasOfInterestData.map((i) => {
          i.isCheck = false;
        })
        data.tracksData = res.data.agendaTrackLists;
        data.tracksData.map((i) => {
          i.isCheck = false;
        })
        data.journeysData = res.data.agendaJourneyLists;
        data.journeysData.map((i) => {
          i.isCheck = false;
        })
        // data.lists = formatData(res.data.data);
        // loading.close();
      }).catch(err => {
        console.log(err);
      })
    }

    const go = () => {
      // context.emit('go',data.searchListSlide,data.searchList)
      // context.emit('clickGoBtn',input1.value.trim())
      let areasOfInterest = [];
      data.areasOfInterestData.map((i) => {
        if (i.isCheck == true) {
          areasOfInterest.push(i.id);
        }
      })
      let track = [];
      data.tracksData.map((i) => {
        if (i.isCheck == true) {
          track.push(i.id);
        }
      })

      let journey = [];
      data.journeysData.map((i) => {
        if (i.isCheck == true) {
          journey.push(i.id);
        }
      })


      // localStorage.eTracksData = JSON.stringify(track);
      // localStorage.eAreasOfInterest = JSON.stringify(areasOfInterest);
      context.emit('clickGoBtn', input1.value.trim(), journey, track, areasOfInterest)
      data.areasOfInterestData.map((i) => {
        i.isCheck = false;
      })
      data.tracksData.map((i) => {
        i.isCheck = false;
      })
      data.journeysData.map((i) => {
        i.isCheck = false;
      })
      if (props.showSearchBox) {
        store.commit("setSearchSlideOut", false);
      }
      input1.value = '';
      // context.emit('track',track)
      // context.emit('areasOfInterest',areasOfInterest)

    }
    const goSearch = () => {
      // context.emit('go',input1.value.trim())
      if (props.showSearchBox) {
        inputRef.value.blur();//让input失去焦点
        store.commit("setSearchSlideOut", false);
        let areasOfInterest = [];
        data.areasOfInterestData.map((i) => {
          if (i.isCheck == true) {
            areasOfInterest.push(i.id);
          }
        })
        let track = [];
        data.tracksData.map((i) => {
          if (i.isCheck == true) {
            track.push(i.id);
          }
        })

        let journey = [];
        data.journeysData.map((i) => {
          if (i.isCheck == true) {
            journey.push(i.id);
          }
        })
        context.emit('clickGoBtn', input1.value.trim(), journey, track, areasOfInterest);
        data.areasOfInterestData.map((i) => {
          i.isCheck = false;
        })
        data.tracksData.map((i) => {
          i.isCheck = false;
        })
        data.journeysData.map((i) => {
          i.isCheck = false;
        })
        if (props.showSearchBox) {
          store.commit("setSearchSlideOut", false);
        }
        input1.value = '';
      } else {
        context.emit('clickGoBtn', input1.value.trim())
      }
    }
    const searchFocus = () => {
      if (props.showSearchBox) {
        store.commit("setSearchSlideOut", true);
      }
    }
    const inputChange = () => {
      context.emit('change', input1.value.trim())
    }
    const clickSlideItem = (type, item, index) => {
      // data.tracksData.map(item => {
      //   item.isCheck = !item.isCheck;
      // })
      item.isCheck = !item.isCheck;
      if (item.isCheck == true) {
        if (type == 'track') {
          data.trackAll.isCheck = false;
        }
        if (type == 'journey') {
          data.journeyAll.isCheck = false;
        }
      }


    }
    const clickCheckbox = (event) => {
      event.stopPropagation();  //如果是连接还是会打开
    }
    const clickSearchBox = () => {
      store.commit("setSearchSlideOut", false);
    }
    return {
      store,
      input1,
      inputRef,
      // keyup,
      searchFocus,
      inputChange,
      swiper_track,
      swiper_journey,
      clickSlideItem,
      go,
      goSearch,
      clickCheckbox,
      getFilterData,
      clickSearchBox,
      ...toRefs(data)
    };
  },
};
</script>
<style lang='scss'>
.search {
  position: relative;

  .el-input__inner {
    border: 2px solid #74c6f0;
    border-radius: 50px;
    height: 30px;
    line-height: 30px;
  }

  .searchBottomBox {
    width: 580px;
    //height: 500px;
    position: absolute;
    top: 45px;
    right: 0;
    z-index: 1000;
    background: #fff;
    border: 1px solid #999;
    //overflow: scroll;
    .closeContainer{
      //display: flex;
      //justify-content: end;
      text-align: right;
      margin: 5px 5px 15px 0;
      .icon-guanbi {
        font-size: 26px;
        cursor: pointer;
      }
    }


    .table {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid #999;

      p {
        cursor: pointer;
        padding: 10px;
        width: 50%;
        min-height: 30px;
        box-sizing: border-box;
        word-break: break-all;

        &:nth-child(odd) {
          border-right: 1px solid #999;
          border-bottom: 1px solid #999;
        }

        &:nth-child(even) {
          border-bottom: 1px solid #999;
        }

        &:nth-last-child(2) {
          border-bottom: none;
        }

        &:last-child {
          border-bottom: none;
        }

        .text {
          font-size: 15px;
          margin-left: 5px;
        }
      }
    }
  }

  .searchBottomBox::-webkit-scrollbar {
    display: none;
  }
}
</style>

<!--轮播图样式-->
<style lang="scss">
.searchBottomBox {
  .swiper-slide {
    height: 30px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: auto !important;
    margin: 0 10px;
    padding: 0 10px;
    border: 1px solid;

    &.checked {
      background: red;
      color: #fff;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    color: gray !important;
    font-weight: bold;
  }

  // --swiper-navigation-size: 20px;
}

.slide {
  .swiperP {
    padding-left: 12px;
    color: #13254A;
    font-size: 0.9rem;
    //margin: 10px 0;
  }
}

.slide .swiper-container {
  padding: 0 30px;
}

.slide .swiper-container .swiper-button-prev {
  left: 0;
  background: #fff;
}

.slide .swiper-container .swiper-button-next {
  right: 0;
  background: #fff;
}

.slide .swiper-container .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  /* color:#13254A; */
  background: url("~@/assets/imgs/agendaIcon/forward-caret.png") no-repeat center center;
  content: '' !important;
  width: 100%;
  height: 100%;
  transform: rotate(180deg);
}

.slide .swiper-container .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
  /* color:#13254A; */
  background: url("~@/assets/imgs/agendaIcon/forward-caret.png") no-repeat center center;
  content: '' !important;
  width: 100%;
  height: 100%;
}
</style>

<style scoped lang="scss">
@media screen and (min-width: 332px) and (max-width: 996px) {
  .search {
    .searchBottomBox {
      width: 450px !important;
      left: -50px;
    }
  }
}

</style>

