import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw} from 'vue-router';
import Login from '../views/Login.vue';
import Index from '../views/Index.vue';
import axios from "@/api/http";


// let head = document.getElementsByTagName('head');
// let meta = document.createElement('meta');
// meta.name = 'referrer';
// //根据实际情况修改referrer的值，可选值参考上文
// // meta.content = 'no-referrer';
// meta.content = 'https://inpixonsko22.com';
// head[0].appendChild(meta);


let client = localStorage.getItem('eventClient');


// 获取当前的用户
async function getClient() {
    await axios.request({
        method: 'GET',
        url: '/cms/config',
    }).then(res => {
        client = res.data.Client;
    }).catch(err => {
        console.log(err);
    })
}
// getClient();
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: async() => {
            if (client == null) await getClient();////////////////
            console.log("==== client222222=====", client)
            if (client == 'inpixon') {
                return import('../views/inpixon/Login.vue')
            } else if (client == 'vmware') {
                return import('../views/Login.vue')
            }  else if (client == 'Aruba') {
                // return import('../views/aruba/Login.vue')
                return import('../views/aruba/Login2.vue')
            }else {
                return import('../views/Login.vue')
            }
        },
    },
    {
        path: '/registration',
        name: 'Registration',
        component: async() => {
            if (client == null) await getClient();////////////////
            if (client == 'inpixon') {
                return import('../views/inpixon/Registration.vue')
            } else if (client == 'vmware') {
                return import('../views/Registration.vue')
            } else {
                return import('../views/Registration.vue')
            }
        },
        // component: () => import ('../views/Registration.vue')
    },
    {
        path: '/mec',
        name: 'Mec',
        component: () => import ('../views/mec.vue')
    },
    {
        path: '/test',
        name: 'Test',
        component: () => import('../views/test.vue'),
    },
    {
        path: '/index',
        name: 'Index',
        component: Index,
        redirect: '/home',
        children: [
            // {
            //   path: 'home',
            //   name: 'Home',
            //   component: () => import('../views/mainPage/navHome.vue')
            // },
            {
                path: '/home',//子组件的路径前加'/'则默认为根路由，url上就不显示父组件的path，即/home。如果不加'/'，则会显示父组件的path，如/index/home
                name: 'Home',
                meta: {
                    keepAlive: true,
                },
                component: () => {
                    if (client == 'inpixon') {
                        return import('../views/mainPage/aruba/navHome.vue')
                    } else if (client == 'vmware') {
                        return import('../views/mainPage/navHome.vue')
                    } else if (client == 'Aruba') {
                        // return import('../views/aruba/Login.vue')
                        return import('../views/mainPage/aruba/navHome.vue')
                    }
                    // if(false){
                    //   return import('../views/mainPage/hpe/navHome.vue')
                    // }else{
                    //   return import('../views/mainPage/navHome.vue')
                    // }
                },
            },
            {
                path: '/allContents',
                name: 'AllContents',
                component: () => import('../views/mainPage/navAllContents.vue')
            },
            {
                path: '/trainings',
                name: 'Trainings',
                component: () => import('../views/mainPage/aruba/trainings.vue')
            },
            {
                path: '/airheadsSessions',
                name: 'Airheads Sessions',
                component: () => import('../views/mainPage/aruba/airheadsSessions.vue')
            },
            {
                path: '/webview',
                name: 'Webview',
                component: () => import('../views/mainPage/iframe.vue')
            },
            {
                path: '/tracks',
                name: 'Tracks',
                component: () => import('../views/mainPage/navAgenda.vue')
            },
            {
                path: '/journeys',
                name: 'Journeys',//这个名字别随便改，navAgenda.vue有用到
                component: () => import('../views/mainPage/navAgenda.vue')
            },
            // {
            //     path: '/tracks',
            //     name: 'Tracks',
            //     component: () => import('../views/mainPage/navTracks.vue')
            // },
            // {
            //     path: '/journeys',
            //     name: 'Journeys',
            //     component: () => import('../views/mainPage/navJourney.vue')
            // },
            {
                path: '/trainingResources',
                name: 'TrainingResources',
                component: () => import('../views/aruba/trainingResources.vue')
            },
            {
                path: '/content',
                name: 'Content',
                component: () => import('../views/mainPage/navContent.vue')
            },
            {
                path: '/keynoteDetail',
                name: 'KeynoteDetail',
                component: () => import('../views/mainPage/navContentKeyNote.vue')
            },
            {
                path: '/attendees',
                name: 'Attendees',
                component: () => import('../views/mainPage/navContributors.vue')
            },
            {
                path: '/contributors',
                name: 'Contributors',
                component: () => import('../views/mainPage/navContributors.vue')
            },
            {
                path: '/presentation',
                name: 'Presentation',
                component: () => import('../views/mainPage/navPresentation.vue')
            },
            {
                path: '/notification',
                name: 'Notification',
                component: () => import('../views/mainPage/navNotification.vue')
            },
            {
                path: '/polls',
                name: 'Polls',
                component: () => import('../views/mainPage/navPolls.vue')
            },
            {
                path: '/survey',
                name: 'Survey',
                component: () => import('../views/mainPage/navSurvey.vue')
            },
            {
                path: '/virtualBriefcase',
                name: 'VirtualBriefcase',
                component: () => import('../views/mainPage/navVirtualBriefcase.vue')
            },
            {
                path: '/resources',
                name: 'Resources',
                component: () => import('../views/mainPage/navResource.vue')
            },
            {
                path: '/activityStream',
                name: 'ActivityStream',
                component: () => import('../views/mainPage/navActivityStream.vue')
            },
            {
                path: '/favorites',
                name: 'Favorites',
                meta: {
                    keepAlive: true,
                },
                component: () => import('../views/mainPage/navFavorites.vue')
            },
            {
                path: '/agenda',
                name: 'Agenda',
                meta: {
                    keepAlive: true,
                },
                component: () => import('../views/mainPage/Agenda.vue')
            },
            {
                path: '/innovationZone',
                name: 'InnovationZone',
                meta: {
                    keepAlive: true,
                },
                component: () => import('../views/mainPage/navInnovationZone.vue')
            },
            {
                path: '/searchAgenda',
                name: 'searchAgenda',
                meta: {
                    keepAlive: true,
                },
                component: () => import('../views/mainPage/searchAgenda.vue')
            },
            {
                path: '/keynotes',
                name: 'KeyNotes',
                meta: {
                    keepAlive: true,
                },
                component: () => import('../views/mainPage/navKeyNotes.vue')
            },
            {
                path: '/profile',
                name: 'Profile',
                meta: {
                    keepAlive: true,
                },
                component: () => import('../views/mainPage/navProfile.vue')
            },

        ]
    }
];
const router = createRouter({
    history: createWebHashHistory(),
    // history: createWebHistory(process.env.BASE_URL),
    // history: createWebHistory(),
    routes
});

export default router;
