import axios from 'axios';
import router from "@/router";

axios.interceptors.request.use((config) => {
    // 一般在这个位置判断token是否存在
    // let token = "FB76TXI2DcLQet8oBYTgrn2W";
    let token = localStorage.getItem("webToken");
    if(token){
        // 去掉token两边的引号(直接在url拼接?token,localStorage.token两侧就多了个引号,
        // 传给后台就报错了,正常登录存到localStorage的token两侧就没有引号)
        var reg = new RegExp('"',"g");
        token = token.replace(reg, "");
    }
    config.headers.CX_TOKEN = token;
    return config;
}, (error) => {
    console.log("ERROR:", error.response)
    console.log("ERROR:", error)
    // 对请求错误做些什么
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    // 处理响应数据
    if(response.status === 401){
        console.log(router.currentRoute.name,888888)
        localStorage.webToken = '';
        // router.push({name:'Login'})
        return
    }
    // if (status == 401) {
    //     //判断当前的路由是否是目标路由
    //     console.log(router.currentRoute.name )
    //     if(router.currentRoute.name == "target"){
    //      //跳转回login路由，并把目标路由的url路径保存在login的query中
    //         router.replace({
    //             name:"login",
    //             query: {redirect: router.currentRoute.fullPath}
    //         })
    //     }else{
    //      /* 普通401拦截直接返回到登录页面 */
    //         router.push('/login');
    //     }
     
    // }
    if (response.status === 200) {
        if(response.data.result=='401'){
            router.push({name:'Login'})
            return
        }
        return Promise.resolve(response);
    } else {
        return Promise.reject(response);
    }
}, (error) => {
    console.log("ERROR:", error.response.status)
    if(error.response.status === 401){
        localStorage.webToken = '';
        localStorage.redirectUri = router.currentRoute.value.name;
        router.push('/login');
        // console.log(router.currentRoute.value.name,9999)
        // //判断当前的路由是否是目标路由
        // console.log('currentRouteName: ',router.currentRoute.value.name,9999 )
        // if(router.currentRoute.name != "Login"){
        //  //跳转回login路由，并把目标路由的url路径保存在login的query中
        //     router.replace({
        //         name:"login",
        //         query: {redirect: router.currentRoute.value.fullPath}
        //     })
        // }else{
        //  /* 普通401拦截直接返回到登录页面 */
        //     router.push('/login');
        // }
    }
    console.log("ERROR:", error)
    // 处理响应失败
    return Promise.reject(error);
});

export default axios

