import {createApp} from 'vue';
import ElementPlus from 'element-plus';
// import 'element-plus/lib/theme-chalk/index.css';
import 'element-plus/dist/index.css'
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/global.scss';
import './assets/iconfont/iconfont.css';
// import './assets/css/vmware.scss';
import swiper from '@/plugins/swiper'
import axios from '@/api/http.js'
// import VueGtag from 'vue-gtag';
import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css
import elq from '@/utils/arubaElq.js';

const app = createApp(App);

let client = localStorage.getItem("eventClient");
console.log("====client!!!!!!!!!!!! =====", client)
if (client != "" && client != null) {
    if (client == "inpixon") {
        document.title = 'Inpixon'
        require.ensure([], function (require) {
            require('@/assets/css/inpixon.scss');
        })
    } if (client == "Aruba") {
        document.title = 'Aruba Atmosphere 2022'
        require.ensure([], function (require) {
            require('@/assets/css/aruba.scss');
        })
    } else {
        document.title = 'Event'
        require.ensure([], function (require) {
            require('@/assets/css/vmware.scss');
        })
    }
} else {
    getClient();
}

// 获取当前的用户
function getClient() {
    axios.request({
        method: 'GET',
        url: '/cms/config',
    }).then(res => {
        let client = res.data.Client;
        localStorage.setItem("eventClient", client);
        console.log("==== client222222222=====", client)
        if (client == "inpixon") {
            // require ('./assets/css/inpixon.scss');
            require.ensure([], function (require) {
                require('@/assets/css/inpixon.scss');
            })
        } else if (client == "Aruba") {
            // require ('./assets/css/inpixon.scss');
            require.ensure([], function (require) {
                require('@/assets/css/aruba.scss');
            })
        } else {
            // require ('./assets/css/vmware.scss');
            require.ensure([], function (require) {
                require('@/assets/css/vmware.scss');
            })
        }
    }).catch(err => {
        console.log(err);
    })
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// 利用token获取当前user的profile （url直接输入token不需要登录的时候获取用户信息的方法）
async function getUserInfo() {
    await axios.request({
        method: 'GET',
        url: '/m/profile/',
    }).then(res => {
        // console.log(res.data.email,'111');
        localStorage.campusInfo = JSON.stringify(res.data);
        localStorage.info = JSON.stringify(res.data);
        localStorage.elqEmail = res.data.email;
        elq.elqActivityLogin();
    }).catch(err => {
        console.log(err);
    })
}


app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(swiper)
app.use(vue3videoPlay)

// app.use(VueGtag, {
//     config: { id: 'UA-104780762-24' },
//     router,
//     enabled: false,
// });
// app.provide('gtag', app.config.globalProperties.$gtag);
app.mount('#app');

// app.use(store).use(router).use(ElementPlus).use(swiper).mount('#app');


// app.config.globalProperties.window = window

router.beforeEach(async(to, from, next) => {
    console.log("====to =====", to)
    console.log("====from =====", from)
    if(JSON.stringify(to.query) == '{}'){
    }else{
        for(let q in to.query){
            if(q == 'utm_campaign' || q == 'utm_source' || q == 'utm_content' || q == 'utm_medium' || q == 'utm_geo' || q =='gclid'){
                setCookie(q, to.query[q], 7);
            }
        }
    }
    if (localStorage.webToken) {
        console.log("==== Have token current=====",)
        localStorage.redirectUri = '';
        localStorage.redirectUriQuery = '';
        if (to.path == '/login') {
            console.log("==== 走1=====",)
            localStorage.setItem('webToken', '');
            localStorage.setItem('campusInfo', '');
            localStorage.setItem('info', '');
            localStorage.setItem('elqEmail', '');
            next();
        } else if (to.path === null) {
            console.log("==== 走2=====",)
            next('*');
        } else if (from.path == 'login') {
            console.log("==== 走3=====",)
            next('/home');
        } else {
            console.log("==== 走4=====",)
            next();
        }
    } else {
        console.log("==== No token current=====",)
        if (to.query.token) {
            localStorage.setItem("webToken", JSON.stringify(to.query.token));
            await getUserInfo();
            console.log("==== =====",)
            if(localStorage.redirectUri){
                console.log("====redirectUri =====",localStorage.redirectUri)
                console.log("====redirectUriQuery =====",JSON.parse(localStorage.redirectUriQuery))
                next({path:localStorage.redirectUri,query:JSON.parse(localStorage.redirectUriQuery)});
            }else{
                console.log("====去Home 页面！！！ =====",)
                next('/home');
            }

            // next(to.path);


            // if(to.name != 'Login'){
                
            //     next(to.path);
            // }else{
            //     next('/home');
            // }
            
            // if (process.env.NODE_ENV === 'production') {
            //     next('/home');
            //     // next();
            // } else {
            //     // next('/mec');
            //     next('/home');
            // }
        } else {
            // console.log("==== to.path=====", to.path)
            if (to.path == '/login' || to.path == '/registration') {
                next();
            } else {
                console.log('123',to.query,to,to.query.length);
                localStorage.redirectUri = to.name;
                localStorage.redirectUriQuery = '';
                if(JSON.stringify(to.query) == '{}'){
                }else{
                    let query = {};
                    for(let q in to.query){
                        if(q == 'utm_campaign' || q == 'utm_source' || q == 'utm_content' || q == 'utm_medium' || q == 'utm_geo' || q =='gclid'){
                        }else{
                            console.log('q:',q, to.query[q])
                            query[q] = to.query[q]
                        }
                    }
                    // console.log(query)
                    localStorage.redirectUriQuery = JSON.stringify(query)
                }
                console.log(localStorage.redirectUri,localStorage.redirectUriQuery)
                next('/login');
            }
        }
        // if (to.path == '/login') {
        //   next();
        // }else{
        //   next('/login');
        // }
    }

});


router.afterEach((to, from, next) => {
    // console.log("====gtag!!!!!!!!! =====",window['gtag'])
    let eventCategory = to.path;
    let eventLabel = to.name ? to.name : to.path;
    // let id = localStorage.getItem("googleAnaId");
    // console.log("====eventLabel: =====",eventLabel)
    // console.log("====eventCategory: =====",eventCategory)
    let id = "UA-104780762-24";
    // window['gtag']('config', 'page_view', {
    //   page_title: eventLabel,
    //   page_path: eventCategory,
    //   send_to: id
    // })

    window['gtag']('event', 'page_view', {
        event_category: eventCategory,
        event_label: eventLabel,
        send_to: id,
    })
    

})
