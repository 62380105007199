<template>
  <div class="avator" :style="{flexFlow:(reverse==true?'row-reverse':'row')}">
    <p style="text-align:left">
      <span class="fullName">{{ person.firstName }}&nbsp;{{ person.lastName }}</span><br/>
      <span class="title textColor">{{ person.title }}</span>
    </p>
    <div>
      <el-avatar
          :style="{margin:(reverse==true?'0 10px 0 0px':'0 0 0 10px')}"
          v-if="person.img||person.image"
          :src="person.img||person.image"
      ></el-avatar>
      <el-avatar v-else class="avatorCircle" :style="{margin:(reverse==true?'0 10px 0 0px':'0 0 0 10px')}">
        {{ data.firstName[0] }}&nbsp;{{ data.lastName[0] }}
      </el-avatar>
    </div>
  </div>
</template>

<script>
import {reactive, toRefs, onBeforeMount, onMounted, toRaw,watch} from "vue";

export default {
  name: "avator",
  props: {
    reverse: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    }
  },
  setup(props) {
    const person = props.data ? props.data : {};
    const data = reactive({});
    onBeforeMount(() => {
    });
    onMounted(() => {
    });
    return {
      ...toRefs(data),
      person
    };
  },
};
</script>
<style scoped lang='scss'>
.avator {
  display: flex;
  align-items: center;

  .fullName {
    color: #0b59df;
    font-weight: bold;
  }
  .title{
    display: inline-block;
    width:150px;
    word-break: break-word;
  }
}
</style>
