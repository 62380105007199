import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../assets/imgs/Aruba/navBar/navBarIcon.png'


const _withScopeId = n => (_pushScopeId("data-v-2ee14504"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navBar" }
const _hoisted_2 = { class: "hanbuger" }
const _hoisted_3 = {
  key: 0,
  class: "num"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "textColor" }
const _hoisted_7 = {
  key: 1,
  class: "item"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "textColor" }
const _hoisted_11 = {
  key: 0,
  style: {"margin":"auto","display":"flex","margin-top":"180px","margin-bottom":"40px"},
  src: _imports_0,
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_navBarSlideOut = _resolveComponent("navBarSlideOut")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _createElementVNode("i", {
        class: "iconfont icon-shezhi",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickSetting && _ctx.clickSetting(...args)))
      }),
      (_ctx.notificationNumber>0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.notificationNumber), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_el_menu, {
      "default-active": _ctx.path,
      class: "el-menu-vertical-demo",
      router: true,
      "active-text-color": "#About7BE4FF"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navItems, (item, index) => {
          return (_openBlock(), _createBlock(_component_el_menu_item, {
            index: item.index,
            key: index,
            class: "leftNavContainer",
            onClick: ($event: any) => (_ctx.recordGa(item))
          }, {
            default: _withCtx(() => [
              (item.name=='Notifications' && _ctx.notificationNumber > 0)
                ? (_openBlock(), _createBlock(_component_el_badge, {
                    key: 0,
                    value: _ctx.notificationNumber,
                    class: "item",
                    type: "danger"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("img", {
                        src: item.iconActive,
                        alt: ""
                      }, null, 8, _hoisted_4), [
                        [_vShow, item.index==_ctx.route.path]
                      ]),
                      _withDirectives(_createElementVNode("img", {
                        src: item.icon,
                        alt: ""
                      }, null, 8, _hoisted_5), [
                        [_vShow, item.index!=_ctx.route.path]
                      ]),
                      _createElementVNode("span", _hoisted_6, _toDisplayString(item.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                : (_openBlock(), _createElementBlock("p", _hoisted_7, [
                    _withDirectives(_createElementVNode("img", {
                      src: item.iconActive,
                      alt: ""
                    }, null, 8, _hoisted_8), [
                      [_vShow, item.index==_ctx.route.path]
                    ]),
                    _withDirectives(_createElementVNode("img", {
                      src: item.icon,
                      alt: ""
                    }, null, 8, _hoisted_9), [
                      [_vShow, item.index!=_ctx.route.path]
                    ]),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(item.name), 1)
                  ]))
            ]),
            _: 2
          }, 1032, ["index", "onClick"]))
        }), 128)),
        (_ctx.client=='Aruba')
          ? (_openBlock(), _createElementBlock("img", _hoisted_11))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["default-active"]),
    _withDirectives(_createVNode(_component_navBarSlideOut, { notificationCount: _ctx.notificationNumber }, null, 8, ["notificationCount"]), [
      [_vShow, _ctx.store.state.appStore.navBarSlideOut]
    ])
  ]))
}