const appStore = {
  state: {
    notificationChange:false
  },
  getters: {
    getNotificationChange: state => state.notificationChange,
  },
  mutations: {
    setNotificationChange: (state, data) => state.notificationChange = data,
  },
}

export default appStore;
